::-webkit-scrollbar {
    width: 10px; 
    height: 10px; 
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px; 
  }
  
  ::-webkit-scrollbar-thumb {
    background: #e0a96d; 
    border-radius: 10px; 
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #ddc3a5; 
  }
  