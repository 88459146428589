.footer {
    height: auto; 
    padding: 2vh 4vh;
    display: flex;
    align-items: center; 
    justify-content: center; 
    text-align: center;
    background-color: #111;
    color: #fff;
    font-size: 0.5rem;
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);
}

.footer p {
    margin: 0;
    font-size: 0.7rem; 
}

.footer a {
    color: #f0a500; 
    text-decoration: none; 
    transition: color 0.3s ease-in-out; 
}

.footer-link img{
    max-height: 3vh;
    width: auto;
}
.footer a:hover {
    color: #fff; 
}

@media (max-width: 768px) {
    .footer {
        padding: 1.5vh 2vh; 
        text-align: center;
        font-size: 0.8rem; 
        height: auto; 
    }

    .footer p {
        font-size: 0.9rem; 
    }
}

@media (max-width: 1200px) {
    .footer {
        padding: 3vh 6vh; 
    }

    .footer p {
        font-size: 1rem; 
    }
}

@media (max-width: 480px) {
    .footer {
        padding: 1vh 1.5vh; 
        font-size: 0.75rem; 
        text-align: center; 
    }

    .footer p {
        font-size: 0.85rem;
    }
}
