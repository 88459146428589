.service-container {
 background-color: #fff;
  background-position: center;
  max-width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.service-container h2 {
  font-size: 3vh; 
  text-align: center; 
  border-bottom: 2px solid #e0a96d;
  padding-bottom: 0.5vh; 
  display: flex;; 
  margin-bottom: 2vh;
  padding-top: 1.5vh;
  color: black;
  justify-content: center;
  
}

.swiper {
  width: 95%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.swiper-slide {
  font-size: 2vh;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
}

.swiper-slide img {
  display: block;
  border-radius: 1vh;
  width: auto;
  height: 44vh;
}

.swiper-slide h3 {
  font-weight: 200;
  margin-top: 1vh;
  font-size: 2.5vh;
  color: black;
  text-align: center;
  margin-bottom: 5vh;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}


.swiper-pagination {
  position: absolute;
  bottom: 2vh;
  left: 50%;
  z-index: 1;
  text-align: center;
}

.swiper-pagination-bullets {
  display: flex;
  justify-content: center;
}

.swiper-pagination-bullet {
  width: 1.2vh;
  height: 1.2vh;
  background-color: #ddc3a5;
  border-radius: 50%;
  opacity: 0.7;
  margin: 0 0.5vh;
}

.swiper-pagination-bullet-active {
  background-color: #e0a96d;
  opacity: 1;
  transform: scale(1.2);
}

.swiper-button-next, .swiper-button-prev {
  position: absolute;
  transform: translateY(-50%);
}
.services h3{
  background-color: transparent;
}
.swiper-button-prev {
  color: black;

}

.swiper-button-next {
  color:black;

}

@media (max-width: 480px) {
  .service-container h2{
    font-size: 3vh;
  }
  .service-container {
    background-attachment: fixed;
  }
  .swiper {
    width: 100%; 
  }

  .swiper-slide img {
    height: 34vh;
    width: auto; 
  }

  .swiper-slide h3 {
    font-size: 2vh; 
  }

  .swiper-pagination {
    bottom: 1.5vh; 
  }

  .swiper-pagination-bullets {
    margin-bottom: 1vh;
  }
}

@media (min-width: 481px) and (max-width: 900px) {
  .service-container {
    background-attachment: fixed;
  }
  .service-container h2{
    font-size: 2vh;
  }
  .swiper {
    width: 100%; 
  }

  .swiper-slide img {
    height: 34vh; 
    width: auto;
  }

  .swiper-slide h3 {
    font-size: 1.3vh; 
  }

  .swiper-pagination {
    bottom: .15vh;
  }

  .swiper-pagination-bullets {
    margin-bottom: 1vh;
  }
}
