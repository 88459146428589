.makaleler-container {
    background-color: #f4f4f4;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; 
    gap: 1vh;
    padding: 2vh;
    margin: 0 auto;
    max-width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-family: 'Roboto', sans-serif;
    min-height: 100vh;
    width: 100%;
}

.makaleler-header {
    width: 100%;
    text-align: center;
    color: black;
    padding-bottom: 1vh;
    border-bottom: 2px solid #e0a96d;
    font-size: 4vh;
}

.makaleler-card {
    flex: 1 1 23%; 
    margin: 1vh; 
    height: 52vh;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    transition: box-shadow 0.3s ease-in-out;
}

.makaleler-card:nth-last-child(-n+2) {
    flex: 1 1 23%; 
}

.makaleler-card:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.makaleler-media {
    height: 30vh;
    object-fit: cover;
}

.makaleler-card-title {
    font-weight: bold;
    color: black;
}

.makaleler-card-description {
    color: #7f8c8d;
}

.makaleler-button {
    background-color: #e0a96d !important;
    color: white !important;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    text-align: center;
    text-decoration: none;
}

.makaleler-button:hover {
    background-color: #ddc3a5 !important;
    color: white !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}




@media screen and (max-width: 1024px) {
    .makaleler-card {
        flex: 1 1 45%; 
        height: 40vh;
    }

    .makaleler-media {
        height: 20vh;
    }

    .makaleler-header {
        font-size: 2.5vh;
    }
}


@media screen and (max-width: 768px) {
    .makaleler-card {
        flex: 1 1 90%; 
        height: 35vh;
    }

    .makaleler-media {
        height: 15vh;
    }

    .makaleler-header {
        font-size: 2vh;
        margin-bottom: 1.5vh;
    }

    .makaleler-container {
        gap: 0.5vh;
        padding: 1vh;
    }
}


@media screen and (max-width: 480px) {
    .makaleler-card {
        min-width: 25vh;
        flex: 1 1 100%;
        height: auto;
    }

    .makaleler-media {
        min-width: 25vh;
        height: 25vh;
    }

    .makaleler-header {
        font-size: 3vh;
    }

    .makaleler-container {
        display: flex;
        flex-wrap: wrap; 
        justify-content: center; 
        padding: 1vh;
    }
}
